import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"
import LatestPosts from "../components/Post/LatestPosts"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Master Cobblers Mackay"
          subTitle="Committed to excellence in quality and service."
        />
        <BasicTextModule
          title="Servicing the Mackay region for over 20 years."
          content="Crafting Quality, Restoring Comfort, and Elevating Style in the Heart of Mackay for Over Two Decades."
          link="/products"
          linkText="View Products"
        />
        <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule>
        <Features
          title="Featured Products from Master Cobblers."
          introduction="Key Cutting, Shoe Repair, Engraving, Watch Repair, and our Collectables – where every item is a testament to our dedication to craftsmanship and precision."
        />
      </Layout>
    </>
  );
}

export default Index
